var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$root.$data.items.length
    ? _c("div", [
        _c("div", { staticClass: "card py-4" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "text-center font-roboto-slab" }, [
              _vm._v("Order Summary")
            ]),
            _vm._v(" "),
            this.orderNumber
              ? _c("small", [
                  _vm._v("Order Number: " + _vm._s(this.orderNumber))
                ])
              : _vm._e(),
            _vm._v(" "),
            this.PONumber
              ? _c("small", [_vm._v(" / PON: " + _vm._s(this.PONumber))])
              : _vm._e(),
            _vm._v(" "),
            this.showDetails
              ? _c(
                  "table",
                  { staticClass: "table table-borderless mt-5 table-sm" },
                  _vm._l(this.$root.$data.items, function(item) {
                    return _c("tr", [
                      _c(
                        "td",
                        { staticClass: "px-3 w-75", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.actualItemId(item)) +
                              " "
                          ),
                          _c("strong", [
                            _vm._v(
                              "x" +
                                _vm._s(item.quantity) +
                                "/" +
                                _vm._s(_vm.pricePerQty(item)) +
                                " ea."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right pr-3" }, [
                        _c("strong", [
                          _vm._v(
                            "$" +
                              _vm._s(
                                (
                                  _vm.pricePerQty(item) * Number(item.quantity)
                                ).toFixed(2)
                              )
                          )
                        ])
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("table", { staticClass: "table table-borderless mt-4" }, [
              _c("tr", [
                _c("td", [_vm._v("Sub Total:")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "$" +
                      _vm._s(
                        Number(this.$root.$data.details.sub_total).toFixed(2)
                      )
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            this.$root.$data.conditions
              ? _c(
                  "table",
                  { staticClass: "table table-borderless table-sm" },
                  _vm._l(this.$root.$data.conditions, function(condition) {
                    return _c("tr", [
                      condition.name == "IL Sales Tax"
                        ? _c("td", { staticClass: "pl-3" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(condition.name) +
                                "\n                        "
                            ),
                            _vm._m(0, true)
                          ])
                        : _c("td", { staticClass: "pl-3" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(condition.name) +
                                "\n                        "
                            ),
                            condition.attributes.name
                              ? _c("div", { staticClass: "text-gray small" }, [
                                  _vm._v(
                                    "Service: " +
                                      _vm._s(condition.attributes.name)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            condition.attributes.taxNote
                              ? _c("div", {
                                  staticClass: "text-gray small",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      condition.attributes.taxNote
                                    )
                                  }
                                })
                              : _vm._e()
                          ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right pr-3" }, [
                        _vm._v(
                          "$" +
                            _vm._s(
                              Number(condition.attributes.price || 0).toFixed(2)
                            )
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("table", { staticClass: "table table-borderless mt-4" }, [
              _c("tr", [
                _vm._m(1),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c("h4", [
                    _vm._v(
                      "$" +
                        _vm._s(
                          Number(this.$root.$data.details.total).toFixed(2)
                        )
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            this.showCheckout
              ? _c("div", [
                  this.guest
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-lg btn-block",
                          attrs: { href: "/checkout/login-first" }
                        },
                        [_vm._v("Checkout")]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-lg btn-block",
                          attrs: { href: "/checkout/address" }
                        },
                        [_vm._v("Checkout")]
                      )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "btn btn-basic border-none float-right text-upq-orange",
                attrs: { href: "/cart" }
              },
              [_vm._v("EDIT ORDER")]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "text-gray small taxExtemptBtn",
        attrs: { "data-toggle": "modal", "data-target": "#taxExtempt" }
      },
      [_c("strong", [_vm._v("Tax exempt?")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h4", [_vm._v("Total:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }