<template>
    <div>
        <div v-if="this.$root.$data.items.length" class="table-responsive-sm">
            <table class="table table-borderless">
                <thead>
                <tr>
                    <th>Image</th><!--SKU-->
                    <th>Name</th>
                    <th>Price</th>
                    <th class="text-center">Quantity</th>
                    <th>Subtotal</th>
                    <!--<th><button v-on:click="clearCart()" class="btn btn-light" alt="Clear cart"><i class="fas fa-times"></i> <div>CLEAR CART</div></button></th>-->
                </tr>
                </thead>
                <tbody>
                <tr class="py-md-3" v-for="item in this.$root.$data.items">
                    <td>
                        <img :src="productImageLink(item)" class="img img-fluid w-auto mh-100 mx-auto"/>
                    </td>
                    <td class="align-middle" style="min-width: 135px;">
                        <a :href="productLink(item)">SKU: {{ actualItemId(item) }}<br>{{ item.name }}</a>
                    </td>
                    <!--Quantity-->
                    <td class="align-middle text-center" style="min-width: 135px;">
                        <!--{{item.quantity}}-->
                        <!--If the total qty of an item in the cart is over the bale qty then charge by bale price-->
                        <div v-if="balePrice(item) !== false && ( item.quantity === baleQty(item) || Number.isInteger(item.quantity / baleQty(item)) )" class="small">
                            <!--IF whole bales:-->
                            (bale)
                            <br>
                            {{ Number(balePrice(item)) }}/{{productUOM(item)}}
                            <br>Min. Bale Qty: {{ baleQty(item) }}
                        </div>
                        <div v-else-if="bundleQty(item) !== false && bundlePrice(item) !== false" class="small" >
                            <!-- ({{ uomSoldIn(item)}})
                            <br>-->
                            {{ Number(bundlePrice(item)) }}/{{productUOM(item)}}
                        </div>
                        <div v-else class="small" >
                            <!--({{ uomSoldIn(item)}})
                            <br>-->
                            {{ Number(item.price) }}/{{productUOM(item)}}
                        </div>
                        <!--<br> <small>(UOM: {{productUOM(item)}})</small>-->
                        <!--{{uomSoldIn(item)}} {{productUOM(item)}}-->
                    </td>
                    <td class="align-middle">
                        <div class="text-nowrap">
                            <button class="btn basic px-1" v-on:click="decreaseQty(item)" ><i class="fas fa-minus"></i></button>
                            <span class="stepper" >{{Number(item.quantity)}}</span>
                            <!--<input name="product_qty" :value="qtyStep" :step="qtyStep" :min="qtyStep" type="number" class="upq-qty-input text-center" >-->
                            <button class="btn basic px-1" v-on:click="increaseQty(item)" ><i class="fas fa-plus"></i></button>
                        </div>
                    </td>

                    <td class="align-middle">
                        <div>
                            ${{Number(extendedPrice(item)).toFixed(2)}}<br>
                        </div>
                    </td>

                    <td class="align-middle">
                        <button v-on:click="removeProduct(item.id)" class="btn btn-light" alt="Delete from cart"><i class="fas fa-times"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>


        <p v-else>You have no items in your shopping cart. <a href="/home" class="font-roboto-slab-bold d-inline-block"> Shop Now</a></p>

        <div v-if="isLoading" class="loading_screen d-flex">
            <div class="border bg-white p-4 m-auto w-md-25 text-center">Updating...</div>
        </div>
    </div>
</template>
<style>
    .loading_screen{
        position: absolute;
        float: right;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff78;
    }
</style>
<script>
    export default {

        data() {
            return {
                isLoading:false,
                body: '',
                show: false,
                selectedQty:0
            }
        },

        methods: {
            /**
             *
             * @param item
             * @returns {string} Returns the result of product qty * price ea.
             */
            extendedPrice(item){
                //First try and use new Bale and Bundle data if existent. if not then fallback to old tier calculations.
                if(this.balePrice(item) !== false
                    && this.bundlePrice(item) !== false
                    && this.baleQty(item) !== false
                    && this.bundleQty(item) !== false){

                    if(item.quantity >= this.baleQty(item)){
                        return Number(item.quantity * this.balePrice(item));
                    }else{
                        return Number(item.quantity * this.bundlePrice(item));
                    }
                }
                else{
                    if(item && item.attributes){
                        let tiers = item.attributes[this.getIndexFor('tiers',item.attributes)].tiers;
                        let tierPrice = 0;
                        tiers.forEach(function (sellTier){
                            if( Number(item.quantity) >= Number(sellTier.Quantity)){
                                tierPrice = sellTier.Price;
                            }else{
                                tierPrice = tiers[0].Price;
                            }
                        });
                        if(Number(item.quantity) > 1){
                            return (Number(tierPrice) * Number(item.quantity));
                        }else{
                            return Number(tierPrice);
                        }
                    }
                }
            },

            productImageLink(item){
                let baseUrl = window.location.origin;
                if (item == null) return '/images/product_placeholder.jpg';
                if(!item.attributes[this.getIndexFor('image',item.attributes)] || item.attributes[this.getIndexFor('image',item.attributes)] == undefined) return '/images/product_placeholder.jpg';
                return baseUrl+'/storage/products/'+
                    item.attributes[this.getIndexFor('image',item.attributes)].image;
            },

            bundlePrice(item){
                if(this.getIndexFor('bundlePrice',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('bundlePrice',item.attributes)].bundlePrice;
                }else{
                    return false;
                }
            },

            bundleQty(item){
                if(this.getIndexFor('bundleQty',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('bundleQty',item.attributes)].bundleQty;
                }else{
                    return false;
                }
            },

            balePrice(item){
                if(this.getIndexFor('balePrice',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('balePrice',item.attributes)].balePrice;
                }else{
                    return false;
                }
            },

            baleQty(item){
                if(this.getIndexFor('baleQty',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('baleQty',item.attributes)].baleQty;
                }else{
                    return false;
                }
            },

            uomSoldIn(item){
                if(this.getIndexFor('productUOMQty',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('productUOMQty',item.attributes)].productUOMQty;
                }else{
                    return false;
                }
            },

            productUOM(item){
                if(this.getIndexFor('productUOM',item.attributes) > -1){
                    return  item.attributes[this.getIndexFor('productUOM',item.attributes)].productUOM;
                }else{
                    return false;
                }
            },

            productLink(item){
                return item.attributes[this.getIndexFor('productPath',item.attributes)].productPath;
            },

            clearCart(productId){
                this.$root.clearCart();
            },

            removeProduct(productId){
                let me = this;
                this.isLoading = true;
                this.$root.removeItem(productId).then(function(response){
                    if(response != true){
                        console.log('Error removing product from cart.');
                    }
                    me.isLoading = false;
                });
            },

            decreaseQty(item){
                let me = this;
                let qtyStep = Number(item.attributes[this.getIndexFor('minQty',item.attributes)].minQty);
                let tiers = item.attributes[this.getIndexFor('tiers',item.attributes)].tiers;
                let tierPrice = 0;

                if(Number(item.quantity) > qtyStep){
                    me.isLoading = true;

                    //if the decrease in qty no longer fulfills the qty requirement for whole bales.
                    if(!Number.isInteger((item.quantity - qtyStep) / this.baleQty(item))){

                        //if this cart item is marked as qualifying 'bale/s' -
                        // replace it as a qualifying bundle by removing '-B' from its id and transfer all other data.
                        if(item.id.search('-B') !== -1){

                            //check if bundles already exist for this item.
                            let hasBundledItemsIndex = this.$root.$data.items.findIndex(x => x.id === this.actualItemId(item));
                            if(hasBundledItemsIndex !== -1){
                                //decrease from the bundles or remove them entirely before breaking out qty from the bale.
                                let bundledItem = this.$root.$data.items[hasBundledItemsIndex];
                                if(bundledItem.quantity > qtyStep){
                                    bundledItem.quantity -= qtyStep;

                                    this.$root.updateQty({'id':bundledItem.id, 'qty': bundledItem.quantity, 'price':bundledItem.price, 'attributes':item.attributes}).then(function(response){
                                        if(response != true){
                                            console.log('Error increasing product qty in cart.');
                                        }
                                        me.isLoading = false;
                                    });
                                }else{
                                    this.$root.removeItem(bundledItem.id).then(function(response){
                                        if(response != true){
                                            console.log('Error removing product from cart.');
                                        }
                                    });
                                    me.isLoading = false;
                                }
                            }else{
                                //decrease qty in the item
                                item.quantity -= qtyStep;

                                let newBundleItemPrice = 0;
                                if (Number.isInteger((item.quantity) / this.bundleQty(item)) === true) {
                                    newBundleItemPrice = this.bundlePrice(item);
                                }

                                let newBundleItem = {
                                    'id': this.actualItemId(item),
                                    'name': item.name,
                                    'qty': item.quantity,
                                    'price': Number(newBundleItemPrice),
                                    'attributes': item.attributes
                                };

                                this.$root.removeItem(item.id).then(function(response){
                                    if(response != true){
                                        console.log('Error removing product from cart.');
                                    }
                                    me.$root.addItem(newBundleItem).then(function(response){
                                        if(response != true){
                                            console.log('Error increasing product qty in cart.');
                                        }
                                        me.isLoading = false;
                                    });
                                });
                            }

                        }
                        //else this product was a qualifying bundle and not a bale up to this point
                        else{
                            item.quantity -= qtyStep;
                            //check if the new pricing logic is usable here. If not use tiers as usual;
                            if(this.baleQty(item) !== false && this.balePrice(item) !== false){
                                tierPrice = this.balePrice(item);
                            }
                            else if(this.bundleQty(item) !== false && this.bundlePrice(item) !== false){
                                tierPrice = this.bundlePrice(item);
                            }
                            // else use the old pricing through pricing tiers.
                            else{
                                tiers.forEach(function (sellTier){
                                    if( Number(item.quantity) >= Number(sellTier.Quantity) ){
                                        tierPrice = sellTier.Price;
                                    }else{
                                        tierPrice = tiers[0].Price;
                                    }
                                });
                            }

                            //update the cart item through the id. Passing just the attributes we would like to update.
                            this.$root.updateQty({'id':item.id, 'qty': item.quantity, 'price':tierPrice, 'attributes':item.attributes}).then(function(response){
                                if(response != true){
                                    console.log('Error increasing product qty in cart.');
                                }
                                me.isLoading = false;
                            });
                        }
                    }
                    //else if the decrease is not fulfilling the full bale requirement.
                    else{
                        // decrease qty
                        item.quantity -= qtyStep;
                        //update price
                        if(this.bundleQty(item) !== false && this.bundlePrice(item) !== false){
                            tierPrice = this.bundlePrice(item);
                        }else{
                            tiers.forEach(function (sellTier){
                                if( Number(item.quantity) >= Number(sellTier.Quantity) ){
                                    tierPrice = sellTier.Price;
                                }else{
                                    tierPrice = tiers[0].Price;
                                }
                            });
                        }

                        //update the cart item through the id. Passing just the attributes we would like to upddate.
                        this.$root.updateQty({'id':item.id, 'qty': item.quantity, 'price':tierPrice, 'attributes':item.attributes})
                        .then(function(response){
                            if(response != true){
                                console.log('Error decreasing product qty in cart.');
                            }
                            me.isLoading = false;
                        });
                    }
                }
            },

            //Increase Product Quantity by the minimum allowed quantity for the Tier
            increaseQty(item){
                let me = this;
                this.isLoading = true;
                let qtyStep = Number(item.attributes[this.getIndexFor('minQty',item.attributes)].minQty);
                let tiers = item.attributes[this.getIndexFor('tiers',item.attributes)].tiers;
                let tierPrice = 0;

                //if the increase fulfills the qty requirement for whole bales.
                if(Number.isInteger((item.quantity + qtyStep) / this.baleQty(item))){
                    //increase qty
                    item.quantity += qtyStep;
                    //if this cart item is NOT marked as qualifying 'bale/s' -
                    // replace it as a qualifying bale by adding '-B' to its id and transfer all other data.
                    if(item.id.search('-B') === -1){
                        let newBaleItemPrice = 0;
                        if (item.quantity === this.baleQty(item)) {
                            newBaleItemPrice = this.balePrice(item);
                        }
                        let newBaleItem = {
                            'id': item.id + '-B',
                            'name': item.name,
                            'qty': item.quantity,
                            'price': Number(newBaleItemPrice),
                            'attributes': item.attributes
                        };

                        this.$root.removeItem(item.id).then(function(response){
                            if(response != true){
                                console.log('Error removing product from cart.');
                            }
                            me.$root.addItem(newBaleItem).then(function(response){
                                if(response != true){
                                    console.log('Error increasing product qty in cart.');
                                }
                                me.isLoading = false;
                            });
                        });
                    }
                    //else this product was a qualifying bale up to this point
                    else{
                        //check if the new pricing logic is usable here. If not use tiers as usual;
                        if(this.baleQty(item) !== false && this.balePrice(item) !== false){
                            tierPrice = this.balePrice(item);
                        }
                        else if(this.bundleQty(item) !== false && this.bundlePrice(item) !== false){
                            tierPrice = this.bundlePrice(item);
                        }
                        // else use the old pricing through pricing tiers.
                        else{
                            tiers.forEach(function (sellTier){
                                if( Number(item.quantity) >= Number(sellTier.Quantity) ){
                                    tierPrice = sellTier.Price;
                                }else{
                                    tierPrice = tiers[0].Price;
                                }
                            });
                        }

                        //update the cart item through the id. Passing just the attributes we would like to upddate.
                        this.$root.updateQty({'id':item.id, 'qty': item.quantity, 'price':tierPrice, 'attributes':item.attributes}).then(function(response){
                            if(response != true){
                                console.log('Error increasing product qty in cart.');
                            }
                            me.isLoading = false;
                        });
                    }
                }
                //else if the increase is not fulfilling the full bale requirement.
                else{
                    //console.log(typeof this.baleQty(item));
                    if(this.baleQty(item) !== false && this.balePrice(item) !== false && item.quantity + qtyStep > this.baleQty(item)) {
                        //if the item quantity without the increase fits qty requirements for one or more bales.
                        //then create a new cart item to fulfill bale/bundle rules.
                        if (Number.isInteger(item.quantity / this.baleQty(item))) {
                            let newItemPrice = 0;
                            if (qtyStep === this.bundleQty(item)) {
                                newItemPrice = this.bundlePrice(item);
                            }
                            let newItem = {
                                'id': this.actualItemId(item),
                                'name': item.name,
                                'qty': qtyStep,
                                'price': Number(newItemPrice),
                                'attributes': item.attributes
                            };
                            this.$root.addItem(newItem).then(function(response){
                                if(response != true){
                                    console.log('Error increasing product qty in cart.');
                                }
                                me.isLoading = false;
                            });
                        }
                    }else{
                        //increase qty
                        item.quantity += qtyStep;

                        tiers.forEach(function (sellTier){
                            if( Number(item.quantity) >= Number(sellTier.Quantity) ){
                                tierPrice = sellTier.Price;
                            }else{
                                tierPrice = tiers[0].Price;
                            }
                        });

                        //update the cart item through the id. Passing just the attributes we would like to upddate.
                        this.$root.updateQty({'id':item.id, 'qty': item.quantity, 'price':tierPrice, 'attributes':item.attributes}).then(function(response){
                            if(response != true){
                                console.log('Error increasing product qty in cart.');
                            }
                            me.isLoading = false;
                        });
                    }
                }
            },

            //returns index of attr key or false
            getIndexFor(attrValue, array){
                let indx = -1
                array.forEach(function (value, index) {
                    if(value[attrValue]){
                        indx = index;
                    }
                });
                return indx;
            },

            actualItemId(item){
                if(this.getIndexFor('actualItmId',item.attributes) > -1){
                    return item.attributes[this.getIndexFor('actualItmId',item.attributes)].actualItmId;
                }else{
                    return false;
                }
            },
        },
    }
</script>
