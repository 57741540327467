var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 col-md-4 px-0" }, [
    _c(
      "form",
      { staticClass: "form-inline" },
      [
        _c("label", { staticClass: "sr-only" }, [
          _vm._v("Search dimensions (L x W x D)")
        ]),
        _vm._v(" "),
        _c("vue-bootstrap-typeahead", {
          ref: "dimTypeahead",
          staticClass: "mb-2 w-75",
          attrs: {
            data: _vm.dimensions,
            name: "selectedDimension",
            placeholder: "Search dimensions (L x W x D)",
            minMatchingChars: _vm.minChars,
            inputClass: "form-control"
          },
          on: {
            hit: function($event) {
              _vm.selectedDimension = $event
            }
          },
          model: {
            value: _vm.dimensionSearch,
            callback: function($$v) {
              _vm.dimensionSearch = $$v
            },
            expression: "dimensionSearch"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group w-25 mb-0" }, [
          _c("label", { staticClass: "sr-only", attrs: { for: "submit" } }, [
            _vm._v("Search")
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              ref: "dimSubmit",
              staticClass: "btn btn-primary mb-2 w-100",
              attrs: { href: _vm.requestUrl, type: "submit" }
            },
            [
              _c("span", { staticClass: "glyphicon glyphicon-search" }),
              _vm._v(" Search")
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }