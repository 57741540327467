var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upq-cart-badge" }, [
    _c("i", {
      staticClass: "fas fa-2x fa-shopping-cart text-black align-middle"
    }),
    _vm._v(" "),
    _c("span", { staticClass: "ml-2 text-black" }, [
      _vm._v(_vm._s(this.$root.$data.itemCount) + " items")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }