var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "alert alert-success",
      attrs: { role: "alert" }
    },
    [
      _c("div", { staticClass: "lead text-upq-blue font-roboto-slab mb-3" }, [
        _vm._v("Added to Cart:")
      ]),
      _vm._v(" "),
      _vm.show
        ? _c("div", { staticClass: "flex flex-col" }, [
            _c("div", { staticClass: "px-1 px-md-2 mb-2" }, [
              _c("img", {
                directives: [{ name: "lazyload", rawName: "v-lazyload" }],
                staticClass: "img-fluid",
                attrs: {
                  src: _vm.itemImage,
                  "data-src": _vm.itemImage,
                  "data-err": "/images/product_placeholder.jpg"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-2" }, [
              _vm._v("\n            " + _vm._s(_vm.itemName) + " "),
              _c("br"),
              _vm._v("Quantity: " + _vm._s(_vm.itemQty) + "\n        ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      this.guest
        ? _c(
            "a",
            {
              staticClass: "btn btn-block bg-upq-yellow text-black mt-4",
              attrs: { href: "/checkout/address" }
            },
            [
              _vm._v("Proceed to Checkout "),
              _c("i", { staticClass: "fas fa-angle-right" })
            ]
          )
        : _c(
            "a",
            {
              staticClass: "btn btn-block bg-upq-yellow text-black mt-4",
              attrs: { href: "/checkout/address" }
            },
            [
              _vm._v("Proceed to Checkout "),
              _c("i", { staticClass: "fas fa-angle-right" })
            ]
          ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "btn a-basic btn-block", attrs: { href: "/cart" } },
        [_vm._v("View and Edit Cart")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }