var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn btn-light",
      attrs: { alt: "Delete from cart" },
      on: { click: _vm.removeProduct }
    },
    [_c("i", { staticClass: "fas fa-times" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }